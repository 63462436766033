import styled from "styled-components";
import { Tab, TabList } from 'react-tabs';

export const MainContainer = styled.div`
  font-size: 16px;
  text-align: center;
  Columnor: #000000;
  padding: ${props => props.padding || "0% 1.5%"};
  margin-bottom :${props => props.marginBottom || "0px"}
`;
export const ReferAndEarnSpan = styled.span`
@media(max-width:1200px){
 display: ${props => props.display}
};
`;

export const Section = styled.div`
margin-top:${(props) => (props.marginTop) || "20px"};
// background-color: ${props => props.background || "#FFFFFF"};
`
export const MatchBanner = styled.div`
background-image:url("/images/matchInfoBack.svg");
background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
  display:flex;
  justify-content:space-evenly;
`
export const MatchBannerTimerDiv = styled.div`
border: 1px solid #BDBDBD;
background:rgba(0,0,0,0.5);
align-self:center;
padding:60px 30px;
`
export const ColumnDiv = styled.div`
  margin-top: 10px;
  background: #FFFFFF;
  padding:${props => props.padding || "20px"};
  border-radius: 8px;
  margin: ${props => props.margin || "10px 0px"};
height: ${props => props.height || "fit-content"};

  min-width: ${props => props.minWidth};
  max-width: ${props => props.maxWidth};
  margin-right: ${props => props.marginRight || "60px"}; 
  width: ${props => props.width || "100%"};
    @media(max-width:1200px){
    max-width:${props => props.mdMaxWidth};
    padding:${props => props.mdPadding};
    height:${props => props.mdHeight};

  margin: ${props => props.mdMargin};



  };
   

   @media(max-width:500px){

    padding:${props => props.smPadding};
    display:${props => props.display};
  };
 
`;
export const LeaderboardBlock = styled.div`
background: ${(props) => (props.background)};
border-radius: 10px;
width: ${props => props.width || "inherit"};


`
export const LeaderboardInnerDiv = styled.div`
z-index: 2;
position: relative;
width:${props => props.width};
background:${props => props.background};
border-radius: 10px;
cursor: pointer;
height:100%;

`
export const LeaderboardBlockRow = styled.div`
display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 8px 19px;

      @media(max-width: 375px){
    padding: 8px;
    };
`
export const LeaderboardText = styled.div`
    font-size:12px;
      width: max-content;

        @media(max-width: 375px){
        font-size:10px;
    };
`
export const LeaderboardImage = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
        // text-align :center;
    @media(min-width:1200px) and (max-width:1300px){
    width: 25px;
    height: 23px;

    };
    @media (max-width: 400px){
      width: 25px;
      height: 25px;
    };
`
export const PredictWinnerText = styled.div`
    padding-right: 40px;
        @media(max-width:1300px){
        font-size:13px;
    };
`
export const ShareImage = styled.img`
    width:100%;
    cursor:pointer;
      @media(max-width:900px){
    margin-bottom:${props => props.smMarginBottom};
    };
       @media (max-width:1199px) {
display:${props => props.display || "none"};
    }

`
export const ShareCodeContainer = styled.div`
    padding:10px;
`
export const ShareCodediv = styled.div`
    border : 2px dotted #BCBBBB;
    display:flex;
    justify-content:space-between;
    padding: 10px 5px;
    border-radius : 5px;
`
export const ShareButton = styled.div`
    border : 2px solid #7DC6FC;
    display:flex;
justify-content: center;
    border-radius : 35px;
    background: #E8F7FF;
    padding: ${props => props.padding || "5px 20px"};
    cursor : pointer;
    width: ${props => props.width || "35%"};
    @media(max-width:480px){
      padding:5px 10px;
      font-size:12px;
    }
`
export const Heading = styled.div`
font-weight: ${props => props.fontWeight || "600"};
padding: ${props => props.padding || "0px"};
font-size:  ${props => props.fontSize || "24px"};
text-align:  ${props => props.textAlign || "left"};
color: ${props => props.color || "#000000"};
padding-bottom:${props => props.paddingBottom || "0px"};
height:${props => props.height};
padding-left: ${props => props.paddingLeft || "0px"};
padding-right: ${props => props.paddingRight || "0px"};
margin-right:${props => props.marginRight || "0px"};
margin-top: ${props => props.marginTop || "0px"};
margin-bottom: ${props => props.marginBottom || "0px"};
margin-left: ${props => props.marginLeft || "0px"};
background-color: ${props => props.background || ""};
display:${props => props.display};
align-items:${props => props.alignItems};
justify-content:${props => props.justifyContent};
width:${props => props.width};

line-height: ${props => props.lineHeight};

margin: ${props => props.margin};
  
    @media(max-width:1340px){
    font-size:${props => props.mdFont};
  };

border: ${props => props.border || "auto"};
border-radius: ${props => props.borderRadius || "auto"};
  @media(max-width:580px){
    font-size:${props => props.smFont};
  };

    @media(max-width:500px){
margin-top:${props => props.mdMarginTop};
  };
    @media(max-width:450px){
    font-size:${props => props.xsFont};
    margin-top:${props => props.smMarginTop};
    text-align:  ${props => props.smTextAlign};
    padding-bottom:${props => props.xsPaddingBottom};
  };
      @media(max-width: 375px){
width:${props => props.xsWidth};
   
  };
 
`
export const SubHeading = styled(Heading)`
font-weight: ${props => props.fontWeight || "200"};
font-size:  ${props => props.fontSize || "16px"};
color: ${props => props.color || "#000000"};

`
export const MatchLogo = styled(LeaderboardImage)`
width:50px;
margin-right:20px
`
export const MatchVenue = styled.div`
background:#F1F3F5;
`
export const MatchVenueDiv = styled.div`
padding:10px;
text-align:left;
display:flex;
`
export const HorizontalLine = styled.div`
border: 0.7px solid #EFEFEF;
margin: ${props => props.margin || "4px"} ;
`
export const HorizontalLineBlue = styled.hr`
border:4px solid #BBE6FF;
width:100px;
background:#BBE6FF;
`
export const TopPlayerdiv = styled.div`
background-color:#F8FAFD;
border: 1px solid #E2E2E2;
padding: 10px 55px;
`
export const TopPlayerImage = styled(LeaderboardImage)`
width:50px
`
export const PlayerScore = styled.span`
font-weight: 600;
`
export const TeamPlayerScore = styled(PlayerScore)`
text-align:left
`

export const TeamScoreDiv = styled.div`
background:#F8FAFD;
border: 1px solid #E2E2E2;
padding: 10px 15px;
margin-top:15px;
border-radius: 5px;
`
export const RecentMatch = styled.div`
background: #F5F5F5;
border-radius:15px;
padding: 8px 15px;
`
export const LastMatchWinBlock = styled.div`
color:#FFFFFF;
background:#359846;
width: 40px;
border-radius:5px;
padding: 5px 7px;

margin: ${props => props.margin}
`
export const LastMatchLoseBlock = styled(LastMatchWinBlock)`
background:#DC3A40;
`
export const FavTeamBlock = styled.div`
font-weight: 600;
width:fit-content;
border-radius:5px;
padding: 1px 7px;
margin: ${props => props.margin}
`
export const RunRateNumber = styled(LastMatchLoseBlock)`
height: ${props => props.height || "24px"};
background: ${props => props.background};
font-size:13px;
margin: ${props => props.margin}; 
padding: ${props => props.padding || "1px 8px"}; 
border : ${props => props.border};
color:${props => props.color || "#FFFFFF"};
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:480px){
      height:20px;
      padding:1px 4px;
      font-size:10px;
      width:20px;
    }
`;
export const Button = styled.button`
border-radius:10px;
color:#FFFFFF;
border:none;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
padding:0px 20px;
`
export const SecondaryButton = styled(Button)`
background: #FFFFFF;
color: #0BA9DB;
border: 1px solid #0BA9DB;
`
export const WicketSplit = styled.div`
width:${props => props.width};
background: ${props => props.background || "#E8F7FF"};
color: ${props => props.color || "#1890E8"};
border:${props => props.border || "1px solid #7DC6FC"};
padding:${props => props.padding || '15px'};
border-radius:38px;
`
export const VenueInfoHead = styled.div`
background: linear-gradient(90deg, rgba(255, 255, 255, 0) 1.51%, #E6E6E6 48.07%, rgba(245, 245, 245, 0) 98.33%);
display:flex;
justify-content:center;
`
export const PieChartDiv = styled.div`
background:#FDFDFD;
border: 0.5px solid #E2E2E2;
padding : 25px;
`
export const BlueBackground = styled(PieChartDiv)`
background:#F5FCFF;
border-color:#7DC6FC
`
export const VerticalLine = styled.div`
background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%);
width:1px;
height:${props => props.height || "150px"};

margin-right :${props => props.marginRight || "0px"};
margin-top :${props => props.marginTop || "0px"};
`
export const CustomTab = styled(Tab)`
border-radius:10px;
color:${props => props.primary ? "#FFFFFF" : "#0BA9DB"};
border:${props => props.primary ? "none" : "1px solid #0BA9DB"};
background: ${props => props.primary ? "linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%)" : "#FFFFFF"} ;
padding:0px 30px;
cursor:pointer;

`;
export const SquadTab = styled(CustomTab)`
border-radius: ${props => props.borderRadius || "24px"};
max-width:${props => props.maxWidth};
width:100%;
padding:${props => props.padding || "5px 30px"};
margin:${props => props.margin};
min-width:${props => props.minWidth || "130px"};
outline: none;

 @media(max-width:375px){
margin:${props => props.xsMargin};
    padding:${props => props.xsPadding};   
    min-width: ${props => props.xsminWidth};

    font-size: ${props => props.xsFont};
  };

`
export const InningTab = styled(CustomTab)`
border-radius: ${props => props.borderRadius || "24px"};
padding:${props => props.padding || "5px 30px"};
`
export const CustomTabList = styled(TabList)`
list-style-type:none;
display:flex;
justify-content:space-between;
width:100%;
padding:0px;
`
export const PlayerDetailDiv = styled(PieChartDiv)`
min-width: 300px;
display:flex;
justify-content:space-between;
padding: 15px 20px;
border-radius: 6px;
margin: 10px 0px;
  @media(max-width:500px){
padding:${props => props.smPadding};
         
  };
    @media(max-width:375px){
min-width: 100%;
         
  };
`
export const Image = styled.img`
width:${props => props.width || "30px"};
padding :${props => props.padding};
margin-right:${props => props.marginRight || "0px"};
margin-left:${props => props.marginLeft || "0px"};
height:${props => props.height || "auto"};
border-radius:${props => props.borderRadius || "0px"};
margin-top:${props => props.marginTop || "0px"};
background:${props => props.background};
// max-width:${props => props.maxWidth || "700px"};
object-fit:${props => props.objectFit};
margin:${props => props.marginAll};
position :${props => props.position || "inherit"};

bottom:${props => props.bottom};
left :${props => props.left};
// style={{ position: "", bottom: "", left: "96px" }} 

cursor: ${props => props.cursor || "auto"};
  @media(max-width:1200px){
   display:${props => props.mdDisplay};
         
  };

    @media(max-width:768px){
  left :${props => props.mdLeft};
         
  };

           
  @media(max-width:580px){
   width:${props => props.smWidth};
   height:${props => props.smHeight};
         
  };
    @media(max-width:450px){
   width:${props => props.xsWidth};
   height:${props => props.xsHeight};
   padding :${props => props.xsPadding};
   bottom:${props => props.xsBottom};
   margin-top:${props => props.xsMarginTop};

  };

border: ${props => props.border || "auto"};
`

export const OverInfoDiv = styled.div`
overflow: scroll;
width: 100%;
max-width: 600px;
margin: 0 auto;
`
export const ScrollableContainer = styled.div`
width: 100%;
overflow-x: auto; /* Enable horizontal scrolling */
white-space: nowrap; /* Prevent content from wrapping */
display:flex;
padding-bottom:15px;
@media(max-width:500px){

min-width: 35px;
padding-bottom:0px;
  
}

`;

export const BallRunCircle = styled.div`
width: ${props => props.width || "50px"};
height:  ${props => props.height || "50px"};
border-radius: 50%;
background:${props => props.background || "#8C91A4"};
color: #FFFFFF;
margin:5px;
font-size: 18px;
min-width: 50px;
display: flex;
justify-content: center;
font-weight:500;
align-items: center;

@media(max-width:500px){

min-width: 35px;
  width:35px;
  height:35px;
  font-size: 14px;
  
}
`;
export const BallByBallCircle = styled(BallRunCircle)`
min-width:${props => props.minWidth};
@media(max-width:480px){
  width:25px;
  height:25px;
  font-size:12px;
}
`


export const MatchScoreTab = styled(Tab)`
display:flex;
align-items:center;
justify-content:space-between;
width: 48%;
background:${props => props.isActive ? "#E8F7FF" : "#FFFFFF"};
border: ${props => props.isActive ? "0.6px solid #7DC6FC" : "none"};
box-shadow:${props => props.isActive ? " 2px 4px 4px 0px #0000000D" : "0px 4px 11px 0px #F2F2F2"};
padding: 15px ;
margin-right:${props => props.marginRight || "0px"};
cursor:pointer;
border-radius: 8px;

outline: none;

&:focus : {
    border:none;
    outline: none;
};
@media(max-width:1380px){
  max-width:240px;
};
@media(max-width:1320px){
  max-width:220px;
  padding: 15px 20px;
};
@media(max-width:520px){
  width:180px;
  padding: 12px 5px;
  margin-right:${props => props.xsMarginRight};
};
`
export const AccordianBanner = styled.div`
background: #FDFDFD;
border: 0.5px solid #E2E2E2;
padding: 10px;
border-radius: 5px;
margin: 10px;
`
export const AccordianInnerDiv = styled.div`
background: #FDFDFD;
border: 0.5px solid #E2E2E2;
padding: 10px;
border-radius: 5px;
margin: 10px;
display: ${({ show, index }) => (show[index] ? "block" : "none")};
@media(max-width: 450px) {
  padding: ${props => props.smPadding};
  background: ${props => props.background || "#FDFDFD"};
  border: ${props => props.border || "0.5px solid #E2E2E2"};
};
`
export const BallRunCircleSmall = styled.div`
display: flex;
justify-content: center;
border-radius: 50%;
background:${props => props.background};
color: #FFFFFF;
width: 28px;
height: 28px;
margin-top: ${props => props.marginTop || "0px"};
align-items: center;
`;

export const BallCommantry = styled.div`
align-items: center;
display: flex;
margin-left: 10px;

`;
export const Percentage = styled.div`

margin-left: -10px;

`;
export const AccordianOnBanch = styled.div`
// background: #F5F5F5;
// border: 0.5px solid #E2E2E2;
// padding: 10px;
border-radius: 5px;

`
export const ColumnDiv620 = styled.div`
  margin-top: 10px;
  background: #FFFFFF;
  padding:20px;
  border-radius: 8px;
  max-width: 700px;
  margin-bottom: 10px;
  height:fit-content;
  width:100%;
  // @media(max-width:1520px) and (min-width:1320px){
  //   max-width: 550px;
  // };
  @media(max-width:1320px){
    max-width: 450px;
  };
  @media(max-width:1200px){
    max-width: 700px;
    width:100%;
  };
`;
export const TotalVotingText = styled.div`
    padding-right: 2px;
    color: #86868C;
     @media(max-width: 500px){
      font-size: 13px;
  };
`
export const PercentText = styled.div`
padding-right: 5px;
padding-top: 10px;
@media (max-width: 400px) {
  font-size: 11px;
}
   
`
export const LineContainer = styled.div`
background-color: ${props => props.background || "#262626"};
padding :${props => props.padding};
margin-top: 10px;
border-radius:5px;
 @media(max-width:580px){
   padding :${props => props.smPadding};
  };
`
export const LineDataContainer = styled.div`
background-color: #0A171B;
margin: 20px 14px;
display:flex;
justify-content:center;
felx-flow:row;
width: 16%;
flex-flow:column;
`
export const BallDetailDiv = styled.div`
background: #F0F0F0;
border-radius : 50px;
padding:  10px 14px;
display:flex;
justify-content:space-between;
margin-bottom:5px;
`
export const Label = styled.div`
width:10px;
height:10px;
background:${props => props.background};
border-radius:50%;
margin:6px 4px 0px 0px;
  @media(max-width:580px){
    width:${props => props.smWidth};
     height:${props => props.smHeight};
     margin:4px 4px 0px 0px;
  };
`
export const LabelContainer = styled.div`
width:70%;
margin: 0 auto;
display:flex;
justify-content:space-evenly;
margin-top:15px;
`
export const FantasyTipsContainer = styled.div`
max-width:500px;
width:100%;
margin: 40px auto 0px auto;
`
export const MatchTimeDiv = styled.div`
background:#FFEFE0;
color:#C57A36;
font-size: 16px;
font-weight:600;
padding: 4px 8px;
border-radius: 35px;
`
export const FancyOddContainer = styled(PieChartDiv)`
background:#FDFDFD;
border: 0.5px solid #E2E2E2;
padding : 25px;
border-radius:5px;
`
export const FancyOddPlayer = styled.div`
width:400px;
display:flex;
justify-content:space-between;
margin-bottom: 15px;
`
export const FancyOddSection = styled.div`
margin : ${props => props.margin};
display:flex;
justify-content:space-between;
`
export const BlogDetailsDiv = styled.div`
display:flex;
justify-content:space-between;
// border-bottom: 1px solid #F1F3F5;
max-width:520px;
padding:20px 0px;
 @media (max-width:900px){
        padding:0px 0px;
    };
`
export const TopBlog = styled.div`
max-width:520px;
padding:20px 0px;
`
export const LineBlog = styled.div`
color: ${props => props.color};
background:${props => props.background};
font-weight: ${props => props.fontWeight || "600"};
margin-right: ${props => props.marginRight || "0px"};
margin-top: ${props => props.marginTop || "0px"};
padding-left: ${props => props.paddingLeft || "0px"};
padding-right = ${props => props.paddingRight || "0px"};
font-size: ${props => props.fontSize || "0px"};
`
export const FancyOddUpperDiv = styled.div`
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
border-radius: 5px;
color:#FFFFFF;
`

export const VerticalVector = styled.div`
background: linear-gradient(180deg, rgba(255, 255, 255, 0.22) 0%, rgba(207, 207, 207, 0.85) 51.56%, rgba(255, 255, 255, 0.22) 100%);
Padding: 0px 1px;
height:70px;
margin: 0px 12px;
`
export const CommentaryDiv = styled.div`
display:flex;
width: 100%;
// background:${props => props.background || "none"};
`
export const OverEndSection = styled.div`
display: flex;
background: linear-gradient(96.3deg, #0BA9DB 27.72%, #0F62C5 118.1%);
border-radius: 5px;
justify-content:space-between;
padding: 10px;
margin-bottom: 15px;


`
export const CommentaryText = styled.div`
display: flex;
justify-content: center;
flex-flow: column;
margin-left: 10px;
`
export const SquadColumn = styled.div`
display: flex;
flex-flow: column;
width: ${props => props.width}
`
export const UmpireSection = styled.div`
width: ${props => props.width}
`
export const RateDiv = styled.div`
background:#F0F0F0;
border-radius:20px;
display:flex;
align-items: center;
justify-content:space-between;
width:220px;
padding: 8px 15px;
margin-bottom:10px;
   @media(min-width:400px) and (max-width:580px){
          width: 180px;
    };
   @media(max-width:400px){
          width: 160px;
    };
`
export const RunRateDiv = styled.div`
background: linear-gradient(90deg, rgba(255, 255, 255, 0) 1.51%, #E6E6E6 48.07%, rgba(245, 245, 245, 0) 98.33%);
display:flex;
justify-content:space-between;
padding: 5px;
margin: 15px 0px;
`

export const BlogBody = styled.div`

width: 85%;
border-bottom: 1px solid #F1F3F5;
background-color: #FFFFFF;
padding: 10px 20px;
margin: 20px auto;
border-radius: 5px;

@media (max-width:1131px) {
  // width: 90%;
  margin: 0 auto;
  padding: 0px;
}

@media (max-width:500px) {
  margin: 0 auto;
  padding: 2px;
  // max-width: 360px;
}
  @media (max-width:425px) {
max-width: 360px;
}
`

export const BlogMainContainer = styled.div`
  font-size: 16px;
  // text-align: center;

  Columnor: #000000;
  padding: 0% 1.5%;
  margin: 20px;
  @media (max-width:1300px) {
     padding:15px 30px;
    margin: 0px auto;
    // max-width: 95%;
  }
  
  @media (max-width:900px) {
    // padding:15px 30px;
    // max-width: 360px;
    margin: 0px auto;
         @media (max-width:500px) {
           font-size: 12px;

}
     @media (max-width:400px) {
          padding:15px 9px;
}

`;
export const RelatedBlog = styled.div`
display:flex;
width: 85%;
justify-content:space-between;
// width: 970px;
border-bottom: 1px solid #F1F3F5;
background-color: #FFFFFF;
padding: 20px;
margin: 20px auto;
border-radius: 5px;
@media (max-width:1300px) {
  padding:15px 5px;
  // max-width: 663px;
  margin: 5px;
}

@media (max-width:900px) {
  padding:15px 1px;
  // max-width: 340px;
  margin: 5px 5px;
}

  @media (max-width:400px) {
    margin: 0px 0px;
}


`
export const AdsDiv = styled.div`

padding: 20px 5px;
@media (max-width:1500px) {
  width: 300px;
   }
@media (max-width:1087px) {
width: 250px;
padding: 0px 10px;
  }
@media (max-width:1011px) {
width: 188px;
padding: 0px 10px;
  }
@media (max-width:850px) {
  display:none;
  }
`
export const RecentFormMainDiv = styled.div`
margin-top: 10px;
// background: #FFFFFF;
padding: 20px 0px;
border-radius: 8px;
width: 100%;

`
export const PlayerTypeHeading = styled.div`
background: linear-gradient(90deg, rgba(255, 255, 255, 0) 1.51%, #E6E6E6 48.07%, rgba(245, 245, 245, 0) 98.33%);
display:flex;
justify-content:center;
margin:${props => props.margin};
font-weight:500;
padding: 6px;
// @media (max-width : 1399px) {
//     font-size:  ${props => props.lgFont};
// };
// @media (max-width : 1170px) {
//     font-size:  ${props => props.mdFont};
// };
// @media (max-width : 1060px) {
//     font-size:  ${props => props.smFont};
// };
`
export const PlayerPerformance = styled.div`
background: ${props => props.background || "#FFFFFF"};
color: ${props => props.color || ""};
border-radius: 20px;
border: ${props => props.border || "1px solid"};
font-size: 12px;
width: 100px;
height: fit-content;
padding: 5px;
margin-top: 8px;
 @media (max-width : 500px) {
   font-size:  ${props => props.smFont};
};
`
export const RecentFormPlayerDiv = styled(PieChartDiv)`
min-width: 300px;
display:flex;
justify-content:space-between;
padding: 15px 20px;
border-radius: 6px;
margin: 10px 0px;
@media(max-width: 500px){
padding:${props => props.smPadding};
};
@media(max-width: 375px){
min-width: 100%;
}
`;
export const SquadDiv = styled.div`
width:100%;
@media(max-width:1200px){
  display:none;
}

`;


