import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Image, MainContainer, BlogMainContainer, BlogBody, RelatedBlog, AdsDiv } from "../MatchInfo/MatchInfoStyle";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";
import { BlogDetailsDiv, BlogImage, ColumnDiv, Heading, HorizontalLine, PaginationContainer, TopBlog, TopBlogImage } from "../pagesStyle";
import { redisApiBaseUrl } from "../../../config";
import { axiosRequest } from "../../../utility/axios";
import { useLocation } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useDispatch } from "react-redux";
import { useSelector } from 'react-redux';
import { setLoader } from "../../../reducers/user";

import Pagination from "@mui/material/Pagination";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";
import { currentBlog } from "../../../reducers/match";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { longToShortDescription } from "../../../utility";
import rightarrow from '../../../image/right_arrow.png'
import SubHeader from "../../components/Header/SubHeader";

export const VerticalLine = styled.div`
border: 0.75px solid #F1F3F5;
`

const ParticularBlogsComponent = () => {
    const [blog, setBlog] = useState([]);
    const [relatedBlog, setRelatedBlog] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const location = useLocation();
    const dispatchMatch = useDispatch();
    const blogData = useSelector(state => state.match.selectBlog);
    let navigate = useNavigate();

    const getBlog = async (id) => {
        dispatchMatch(setLoader({ data: true }));
        window.scrollTo(0, 0);
        let url = `${redisApiBaseUrl}/rssFeedDetails?id=${id}`;
        const BlogDetails = await axiosRequest("GET", url);

        if (BlogDetails && BlogDetails.data) {
            const parser = new DOMParser();
            let contentData = BlogDetails.data.contentData;
            const doc = parser.parseFromString(contentData, "text/html");
            const firstImg = doc.querySelector("figure");
            console.log("firstImg--->>> ", firstImg);

            if (firstImg) {
                firstImg.remove();
            }
            const updatedContentData = doc.body.innerHTML;
            BlogDetails.data.contentData = updatedContentData;
            setBlog(BlogDetails.data);
            let relUrl = `${redisApiBaseUrl}/rssFeedList?types=AllNews`;
            const RelatedBlogs = await axiosRequest("GET", relUrl);

            if (RelatedBlogs && RelatedBlogs.data) {
                setRelatedBlog(RelatedBlogs.data);
            }
            dispatchMatch(setLoader({ data: false }));
        }
    }

    const getRelatedBlog = async (title, id) => {
        const cleanedStr = title.replace(/[^\w\s]/gi, ''); // Removes special characters
        const slugifiedTitle = cleanedStr.replace(/\s+/g, '-');
        dispatchMatch(currentBlog({ id: id, title: slugifiedTitle }));


        navigate(`/blog-info/${slugifiedTitle}/${id}`);
        dispatchMatch(setLoader({ data: true }));
        window.scrollTo(0, 0);
        let url = `${redisApiBaseUrl}/rssFeedDetails?id=${id}`;
        const BlogDetails = await axiosRequest("GET", url);

        if (BlogDetails && BlogDetails.data) {
            setBlog(BlogDetails.data);
            dispatchMatch(setLoader({ data: false }));
        }
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBlogs = relatedBlog.slice(indexOfFirstItem, indexOfLastItem);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        const id = location.pathname.split("/")[3];
        getBlog(id);
    }, [location.pathname]);

    // Format the date after blog data is fetched
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) {
            console.error("Invalid date:", dateString);
            return null;
        }
        return new Intl.DateTimeFormat("en-US", {
            month: "short", // "Nov"
            day: "2-digit", // "15"
            year: "numeric", // "2024"
        }).format(date);
    };

    return (
        <>

            <SubHeader />
            <BlogMainContainer>

                <BlogBody >
                    <Heading margin={"10px auto"} color={"#212529"} marginTop={"10px"} fontSize={"30px"} smFont='24px' xsFont='18px' textAlign='center' fontWeight={"400"} width={"90%"} >{blog.title}</Heading>
                    <HorizontalLine background='#ccc' height='2px' ></HorizontalLine>
                    <Heading margin={"10px 0px"} color={"#a9a5a5"} marginTop={"10px"} fontSize={"16px"} fontWeight={"400"} width={"90%"} >By<span style={{ color: "black", fontWeight: "bold" }} > CricFun </span>- {formatDate(blog.pubdate)}</Heading>

                    <Heading margin={"10px 0px"} color={"#a9a5a5"} marginTop={"10px"} fontSize={"16px"} fontWeight={"400"} width={"90%"} ><a href='/'><span style={{ color: "#007bff", fontWeight: "bold" }} > Home  </span></a><img src={rightarrow} style={{ width: "7px", margin: "0px 4px" }}></img> {blog.title}</Heading>
                    <BlogImageDiv>


                        <SocialMediaContainer>
                            <a href=''>   <SocialMediaIcon src="https://real11-images.s3.ap-south-1.amazonaws.com/image-1733287909552-share.png" alt="Share" /></a>
                            <a href='https://www.whatsapp.com/channel/0029Vaym4Io9WtC8lliNS80g' target="_blank">   <SocialMediaIcon src="https://real11-images.s3.ap-south-1.amazonaws.com/image-1733288094232-whatsup.png" alt="Whats Up" /></a>
                            <a href='https://www.facebook.com/CricFunApp?mibextid=ZbWKwL' target="_blank">   <SocialMediaIcon src="https://real11-images.s3.ap-south-1.amazonaws.com/image-1733288021502-fb.png" alt="Facebook" /></a>
                            <a href='https://x.com/CricFunApp?t=pgGaL61g277wQfKi1JoOIg&s=09' target="_blank">   <SocialMediaIcon src="https://real11-images.s3.ap-south-1.amazonaws.com/image-1733288153477-twitter.png" alt="Twitter" /></a>

                            <a href='https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGC-ZdIy630dQAAAZOMStoAVk9dAC_IxcpkDEySeDQcQEKF4ZT5ihRoAGGOUf8uJUXlmOOBdj6bavQtmbo9M6oDZhZ2OsklBPpboU4YRWIvpW_LFxSeHooDYqj9_gqcpumtDXE=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fcricfun%2F' target="_blank">   <SocialMediaIcon src="https://real11-images.s3.ap-south-1.amazonaws.com/image-1733288060082-linkedin.png" alt="LinkedIn" /></a>
                            <a href='https://in.pinterest.com/cricfunapp/' target="_blank"> <SocialMediaIcon src="https://real11-images.s3.ap-south-1.amazonaws.com/image-1733288077746-pinterest.png" alt="Pinterest" /></a>
                            <a href='https://t.me/CricFunApp' target="_blank">   <SocialMediaIcon src="https://real11-images.s3.ap-south-1.amazonaws.com/image-1733288190833-telegram.png" alt="Telegram" /></a>
                        </SocialMediaContainer>
                        <Image width='100%' src={blog.imageContent}></Image>

                        <div className="content" dangerouslySetInnerHTML={{ __html: blog.contentData }}></div>
                    </BlogImageDiv>


                </BlogBody>
                {/* <Row justifyContent="space-between" > */}
                <Column>

                    <Heading margin={"10px 0px"} color={"#22213A"} marginTop={"10px"} fontSize={"20px"} textAlign='center' fontWeight={"500"} width={"90%"} >{blog.contentData ? "Related Articles" : ""}</Heading>
                    <RelatedBlog padding={"30px 20px"}>

                        <Grid container style={{ justifyContent: "center" }} >
                            {currentBlogs && currentBlogs.length && currentBlogs.map((blog, index) => (
                                <>
                                    <Grid item md={5.9}  >
                                        <ColumnDiv marginTop={"0px"} padding={"0px 10px"} style={{ cursor: "pointer" }}>
                                            <BlogDetailsDiv onClick={() => getRelatedBlog(blog.title, blog.id)} padding={"10px"} >
                                                <BlogImage src={blog.imageContent} width={"200px"} mdWidth={"210px"} margin={"25px"} height={"153px"}></BlogImage>
                                                <Column style={{ width: "50%" }}>
                                                    <Heading color={"#22213A"} fontSize={"17px"} fontWeight={"500"} mdFont={"14px"} padding={"10px"} margin={"0px 0px 8px"}>{longToShortDescription(blog.title, 100, 80)}</Heading>
                                                </Column>

                                            </BlogDetailsDiv>
                                            {/* {console.log(index == currentBlogs.length - 1 || index == currentBlogs.length - 2)} */}
                                            {index == currentBlogs.length - 1 || index == currentBlogs.length - 2 ? "" : <HorizontalLine width={"96%"} margin={"20px 0px 20px 10px"} mdMargin={"5px 0px 5px 10px"} />}

                                        </ColumnDiv>

                                    </Grid>
                                    {
                                        index % 2 !== 0 ? "" : <VerticalLine></VerticalLine>
                                    }


                                </>
                            ))}
                        </Grid>
                    </RelatedBlog>
                    <PaginationContainer>
                        <Pagination
                            count={Math.ceil(relatedBlog.length / itemsPerPage)}
                            page={currentPage}
                            onChange={handleChange}
                            color="primary"
                        />
                    </PaginationContainer>
                </Column>
                <Column>
                    <AdsDiv>
                        {/* <Image width={"100%"} marginTop={"4px"} src="../Assets/dummyad.svg"></Image> */}
                    </AdsDiv>
                </Column>
                {/* </Row> */}
            </BlogMainContainer>


        </>
    )
}

export default ParticularBlogsComponent;
const BlogImageDiv = styled.div`
margin: auto;
width: 75%;
// padding: 20px;
    position: relative; 
`;


const SocialMediaContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    left: -10%;
    // top: 50%;
    // transform: translateY(-50%);
    margin: 5px  15px 0px 0px ;  // Space between the social icons and the image

      @media (max-width:700px) {
display: none;
  }
`;
const SocialMediaIcon = styled.img`
    width: 50px;   // Size of social media icons
    height: 50px;
    margin-bottom: 10px;  // Space between icons


    cursor: pointer;  // Change cursor to pointer when hovering over icons
    &:hover {
        opacity: 0.7;  // Optionally, add hover effect
    }



    @media (max-width:1040px) {
        width: 30px;   // Size of social media icons
         height: 30px;
               }
`;