import React, { useEffect, useState } from "react";
import { BlogColumnDiv, BlogDetailsDiv, BlogImage, ColumnDiv, Heading, HorizontalLine, Image, PaginationContainer, TopBlog, TopBlogImage } from "../pagesStyle";
import { Column, Row } from "simple-flexbox";
import { axiosRequest } from "../../../utility/axios";
import { apiBaseUrl, redisApiBaseUrl } from "../../../config";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ParticularBlogsComponent from "./ParticularBlogComponent";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../reducers/user";
import { Helmet } from "react-helmet";
import Pagination from "@mui/material/Pagination";
import { currentBlog } from "../../../reducers/match";
import { sessionManager } from "../../../managers/sessionManager";
import { cookiesConstants } from "../../../constants";

const BlogDetails = (props) => {
    const [blogs, setBlogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    let navigate = useNavigate();
    const dispatchMatch = useDispatch();

    const getBlogs = async () => {
        dispatchMatch(setLoader({ data: true }));
        let url = `${redisApiBaseUrl}/rssFeedList?types=Blogs`;
        const blogList = await axiosRequest("GET", url);

        if (blogList && blogList.data) {
            setBlogs(blogList.data);
        }
        dispatchMatch(setLoader({ data: false }));
    };

    useEffect(() => {
        getBlogs();
    }, []);

    const getBlog = (id, title) => {
        console.log("hhii");

        const cleanedStr = title.replace(/[^\w\s]/gi, ''); // Removes special characters
        const slugifiedTitle = cleanedStr.replace(/\s+/g, '-'); // Replace spaces with hyphens
        dispatchMatch(currentBlog({ id: id, title: slugifiedTitle }));
        sessionManager.setDataInCookies({ id: id, title: slugifiedTitle }, cookiesConstants.CURRENT_BLOG);
        console.log("slugifiedTitle-->> ", `/blog-info/${slugifiedTitle}/${id}`)
        navigate(`/blog-info/${slugifiedTitle}/${id}`);
    };

    // Logic to get current items based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBlogs = blogs.slice(indexOfFirstItem, indexOfLastItem);

    const handleChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <ColumnDiv marginTop={props?.marginTop} marginBottom={"20px"}  >

                <BlogColumnDiv type={props.type} margin={"10px 10px 0px 10px"}>
                    {currentBlogs.map((blog, index) => (
                        <>
                            {index === 0 ?
                                <TopBlog onClick={() => getBlog(blog.id, blog.title)}>
                                    <Row justifyContent="center" style={{ cursor: "pointer" }}>
                                        <TopBlogImage width={"580px"} mdWidth={"480px"} height={"350px"} src={blog.imageContent}></TopBlogImage>
                                    </Row>
                                    <Heading margin={"10px auto"} mdFont={"16px"} color={"#22213A"} marginTop={"10px"} fontSize={"20px"} fontWeight={"500"} textAlign={"center"} width={"100%"} >{blog.title}</Heading>
                                </TopBlog>
                                :
                                <>

                                    <BlogDetailsDiv onClick={() => getBlog(blog.id, blog.title)} style={{ cursor: "pointer" }}>
                                        <BlogImage src={blog.imageContent} width={"250px"} mdWidth={"210px"} margin={"10px"} height={"153px"}></BlogImage>
                                        <Column style={{ width: "50%" }} >
                                            <Heading color={"#22213A"} smFont={"14px"} fontSize={"17px"} fontWeight={"500"} mdFont={"16px"}>{blog.title}</Heading>
                                        </Column>

                                    </BlogDetailsDiv>
                                    <HorizontalLine />
                                </>
                            }
                        </>
                    ))}
                </BlogColumnDiv>

                <PaginationContainer>
                    <Pagination
                        count={Math.ceil(blogs.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChange}
                        color="primary"
                    />
                </PaginationContainer>

            </ColumnDiv>
        </>
    )
};

export default BlogDetails;
